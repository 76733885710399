<script setup>

  /**
   * 
   * Komponens: egyszerű kereső mező
   * 
   *  - EAN esetén egyből a termék adatlapra irányít
   *  - Lapbetöltéskor az URL-ből visszatölti az értéket
   * 
  */

  import { API } from 'aws-amplify'
  // import * as queries from '~/graphql/queries'
  import * as querySearchByEan from '~/graphql/query-quick-search-by-ean'
  import { useScrollStore } from '@/store/scroll';
  const scrollStore = useScrollStore();

  // nyelvi es routing beallitasok
  const localePath = useLocalePath();
  // const { locale } = useI18n();
  const route = useRoute();
  const router = useRouter();

  const loading = ref(false);
  const searchinfo = ref('');
  const searchtext = ref('');

  // URL seach string
  searchtext.value = route.query.s ? decodeURIComponent(route.query.s) : '';
  
  // is it an EAN13 number or not?
  const checkEan = (val) => {
    if (val.length === 13) {
      return /^\d+$/.test(val)
    }
    return false
  };

// Keresés EAN-ra
const searchByEan = async () => {
  loading.value = true
  try {
    const wildcardtext = searchtext.value.trim()
    // Új: Ellenőrizzük, hogy a wildcardtext nem üres-e
    if (!wildcardtext) {
      throw new Error("A keresési szöveg üres.");
    }

    const dataholder = await API.graphql({
      query: querySearchByEan.ProductByEan,
      variables: { 
        deleted: {
          ne: true,
        },
        ean: wildcardtext,
      },
      authMode: 'AWS_IAM',
    })
    const searchitemsbyean = dataholder.data.ProductByEan.items;
    const numberofitemsbyean = searchitemsbyean.length || 0;
    const localSearchPath = localePath('/search');
    if (numberofitemsbyean === 1) {
      let localbookpath = localePath({
        name: 'book-slug',
        params: { slug: searchitemsbyean[0].id },
      })
      if (searchitemsbyean[0].productTemplate !== 'BOOK') {
        localbookpath = localePath({
          name: 'stationary-and-gift-slug',
          params: { slug: searchitemsbyean[0].id },
        })
      }
      // Új: Ellenőrizzük, hogy a jelenlegi útvonal nem egyezik-e meg a cél útvonallal
      if (route.name !== localbookpath) {
        router.push({ path: localbookpath })
      }
    } else {
      // Új: Ellenőrizzük, hogy a jelenlegi útvonal nem egyezik-e meg a cél útvonallal
      if (route.name !== localSearchPath) {
        router.push({
          path: localSearchPath,
          query: {
            ...route.query,
            stype: 'name',
            s: encodeURIComponent(wildcardtext),
          },
        })
      }
    }
  } catch (error) {
    console.error(error);
    // Új: Hiba esetén értesítjük a felhasználót
    alert("Hiba történt a keresés során. Kérjük, próbálja meg újra.");
    // useBugsnag().notify(new Error(error))
  } finally {
    loading.value = false
  }  
};

  const goToSearchPageAndSearch = async (stype) => {
    console.log('goToSearchPageAndSearch called');
    // TODO: lehet hogy az inputra is van vmi azonnali trim() megoldás:
    searchtext.value = searchtext.value.trim();
    // ha nincs keresendő szöveg ne csináljon semmit
    if (searchtext.value === '' || searchtext.value.length < 2) {
      return;
    }
    // isbn esetén más feladatok:
    if (checkEan(searchtext.value) === true) {
      //  EAN is always 13 chars and it contains numbers only:
      searchByEan();
    } else {
      const localSearchPath = localePath('/search');
      // csak akkor navigaljon el, ha az utvonal mas mint a kereso oldale,
      // más esetben pedig csak frissítse a query-t
      // console.log('route.name: ', localePath(route.path));
      // console.log('localSearchPath: ', localSearchPath);

      // keresés gombra kattintás történt, oldal tetejére ugrás kell:
      //  azaz: bármely oldalon áll és rányom a keresésre, a lap tetejére scroll-ozás kell,
      //  és nem töltheti vissza az előző pozíciót:
      scrollStore.setScrollPosition(0);
      // window.scrollTo({ top: 0 });

      //if (localePath(route.path) !== localSearchPath) {
        // console.log('más oldalon vagyunk, kell a natvigateTo');
        await navigateTo({
          path: localSearchPath,
          query: { 
            ...route.query, 
            stype,
            s: encodeURIComponent(searchtext.value),
          },
        });
      //} else {
        // console.log('ugyanazon az oldalon vagyunk, nem kell natvigateTo');
      //}
    }
  };

</script>

<template>
  <div>
    <div class="relative mx-auto text-gray-600 z-40">
      <input
        v-model="searchtext"
        class="block w-full bg-boxbg-light h-10 px-5 pr-16 rounded-t-md rounded-md text-md focus:outline-none text-txt-dark placeholder-txt-dark placeholder-opacity-50 font-light font-sans"
        type="search"
        name="search"
        autofocus
        autocomplete="off"
        :placeholder="$t('Search')"
        @keyup.enter="goToSearchPageAndSearch('name')"
      />
      <div
        class="flex flex-no-wrap justify-between absolute right-0 top-0 mt-1 mr-1"
      >
        <div
          v-show="searchinfo !== ''"
          class="mt-2 mr-1 w-30 h-8 text-gray text-xs font-sans font-light select-none"
        >
          <span class="text-gray text-md mt-2 mx-1 opacity-50"
            ><font-awesome-icon :icon="['fas', 'life-ring']"
          /></span>
          {{ searchinfo }}
        </div>
        <button
          type="submit"
          class="w-10 h-8 text-center bg-navbgsecondary-dark rounded-md transition-colors duration-300 hover:text-txt-light focus:outline-none"
          :class="searchtext === '' ? 'disabled' : ''"
          @click="goToSearchPageAndSearch('name')"
        >
          <font-awesome-icon
            :icon="['fas', 'search']"
            class="h-4 w-4 fill-current"
          />
        </button>
      </div>
    </div>
  </div>
</template>
